/** **************************** Import Types ****************************** */
import {
    ADDERSS_REQUEST,
    ADDERSS_SUCCESS,
    ADDERSS_FAILURE,

} from "../types/addressType";

const initialState = {
    loading: false,
    addressResponse: "",
    error: "",
};

const addressReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case ADDERSS_REQUEST:
            return {
                ...state,
                loading: true,
                addressResponse: "",
            };
        case ADDERSS_SUCCESS:
            return {
                loading: false,
                addressResponse: action.payload.responseStatus,
                error: "",
            };
        case ADDERSS_FAILURE:
            return {
                loading: false,
                addressResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default addressReducer;
