/** **************************** Import Libs ****************************** */
import { combineReducers } from "redux";

/** **************************** Import Reducers ****************************** */
import { changeStateReducer } from "./reducers/changeStateReducer";
import loginReducer from "./reducers/loginReducer";
import SystemUserReducer from "./reducers/systemUserReducer";
import sidebarMinimizerReducer from "./reducers/sidebarMinimizerReducer";
import contactMessageReducer from "./reducers/contactReducer";
import carrierMessageReducer from "./reducers/carrierReducer";
import jobPostReducer from "./reducers/jobpostReducer";
import testimonialReducer from "./reducers/testimonialReducer";
import blogPostReducer from "./reducers/blogpostReducer";
import userSettingPostReducer from "./reducers/userSettingReducer";
import addressReducer from "./reducers/addressreducer";
import companyStatisticsReducer from "./reducers/companyStatisticsReducer";
import socialMediaReducer from "./reducers/socialMediaReducer";
import blogCategoryReducer from "./reducers/blogCategoryReducer";
import jobPositionReducer from "./reducers/jobPositionReducer";
import PocMessageReducer from "./reducers/pocReducer";
import OurCustomerReducer from "./reducers/ourCustomerReducer";
import EmployeeReviewReducer from "./reducers/employeeReviewReducer";
import PpcMessageReducer from "./reducers/ppcCampaignReducer";
import ppcCampaignTypeReducer from "./reducers/ppcTypeReducer";
import ppcCampaignRegionReducer from "./reducers/ppcRegionReducer";
import portfolioReducer from "./reducers/portfolioReducer";
import PortfolioViewReducer from "./reducers/portfolioViewReducer";

const rootReducer = combineReducers({
  changeStateReducer,
  login: loginReducer,
  SystemUser: SystemUserReducer,
  sidebarMinimizer: sidebarMinimizerReducer,
  contactMessage: contactMessageReducer,
  ppcMessage:PpcMessageReducer,
  carrierMessage: carrierMessageReducer,
  jobPost: jobPostReducer,
  testimonial: testimonialReducer,
  blogPost: blogPostReducer,
  userSetting: userSettingPostReducer,
  address: addressReducer,
  companystatistics: companyStatisticsReducer,
  socialmedia: socialMediaReducer,
  blogCategory: blogCategoryReducer,
  jobPosition: jobPositionReducer,
  poc: PocMessageReducer,
  customer:OurCustomerReducer,
  employee:EmployeeReviewReducer,
  ppcType: ppcCampaignTypeReducer,
  ppcRegion: ppcCampaignRegionReducer,
  portfolio: portfolioReducer,
  portfolioView: PortfolioViewReducer,
});

export default rootReducer;
