/** **************************** Import Types ****************************** */
import {
    PPC_REGION_FAILURE,PPC_REGION_REQUEST,PPC_REGION_SUCCESS
    } from "../types/ppcSettingRegions";
    
    
    const initialState = {
      loading: false,
      storePpcRegionResponse: "",
      error: "",
    };
    
    const ppcCampaignRegionReducer = (state = initialState, action) => {
      switch (action.type) {
        case PPC_REGION_REQUEST:
          return {
            ...state,
            loading: true,
            storePpcRegionResponse: "",
          };
        case PPC_REGION_SUCCESS:
          return {
            loading: false,
            storePpcRegionResponse: action.payload.responseStatus,
            error: "",
          };
        case PPC_REGION_FAILURE:
          return {
            loading: false,
            storePpcRegionResponse: "error",
            error: action.payload,
          };
        default:
          return state;
      }
    };
    
    export default ppcCampaignRegionReducer;
    