/* ************************** Import Packages *************************** **/
import React, { useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { useFormik } from "formik";
import * as yup from "yup";
/* ************************** Import Components *************************** **/
// import Input from "../../components/FormControl/Form";
// import Button from "../../components/FormControl/Buttons/index";
import ButtonLoader from "../../components/Loaders/ButtonLoader";

/* ************************** Import Images *************************** **/
// import Logo from "../../assets/images/applogiq-login.png";
import Logo from "../../assets/images/applogiq-login4.png";

/* *********************** Import Redux - Actions ******************** **/
import { LoginUser } from "../../redux/actions";
/* ************************** Import CSS and Styles*************************** **/
import "./signin.css";
import { EyeStyled } from "./style";

const SignIn = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.login.loading);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Email Id is required")
        .strict()
        .email("Enter valid Email Id")
        .lowercase("Email must be lowercase"),
      password: yup
        .string()
        .required("Password is required")
        .min(5, "Invalid Password"),
    }),

    onSubmit: (userInputDate) => {
      setButtonLoader(!buttonLoader);
      dispatch(LoginUser(userInputDate)).then(() => {
        formik.resetForm();
      });
      formik.values.email = "";
      formik.values.password = "";
    },
  });

  const onKeyDown = (e) => {
    if (e.charCode === 13) {
      formik.handleSubmit();
    }
  };

  const spaceValidate = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const isAlphabet = /\s/.test(keyValue);
    if (isAlphabet) {
      e.preventDefault();
    }
  };
  return (
    <div>
      <div className="container-fluid custom-overflow">
        <div className="row">
          <div className="col-md-8 p-0">
            <div>
              <img
                src={Logo}
                width="100%"
                height="100%"
                className="img-fluid img-alignment"
                alt="logo"
              />
            </div>
            {/* <div>
              <h1 className="text-center">ApplogiQ</h1>
            </div> */}
          </div>
          <div className="col-md-4 sign-in-alignment">
            <div className="text-center">
              <h2 className="applogiq-admin-heading">Applogiq Admin</h2>
            </div>
            <div className="login-applogiq">
              <div className="form-ui w-100">
                <div>
                  <h1 className="sign-in-text mb-3">Login</h1>
                  {/* <p className="credentail-text mb-3">
                      Enter your credentials
                    </p> */}
                </div>
                <form>
                  <div>
                    <label className="label-text mb-1">Email</label>
                    <br />
                    <input
                      width="100%"
                      height="43px"
                      // border-radius="6px"
                      border="0.5px solid rgba(0, 0, 0, 0.4)"
                      id="login-email"
                      placeholder="Email Id"
                      type="text"
                      name="email"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onKeyPress={spaceValidate}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <p className="text-danger mb-0 font-errors">
                        {formik.errors.email}
                      </p>
                    ) : (
                      <p className="mb-0 font-errors"></p>
                    )}
                  </div>
                  <div className="mt-3 position-relative">
                    <label className="label-text mb-1">Password</label> <br />
                    <input
                      width="100%"
                      height="43px"
                      // border-radius="6px"
                      border="0.5px solid rgba(0, 0, 0, 0.4)"
                      id="login-password"
                      placeholder="Password"
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={(e) => onKeyDown(e)}
                      value={formik.values.password}
                    />
                    <EyeStyled>
                      {passwordShown ? (
                        <VscEye
                          size={15}
                          onClick={() => setPasswordShown(false)}
                        />
                      ) : (
                        <VscEyeClosed
                          size={15}
                          onClick={() => setPasswordShown(true)}
                        />
                      )}
                    </EyeStyled>
                    {formik.errors.password && formik.touched.password ? (
                      <p className="text-danger mb-0 font-errors">
                        {formik.errors.password}
                      </p>
                    ) : (
                      <p className="mb-0 font-errors"></p>
                    )}
                  </div>
                  <div className="mt-4">
                    <button
                      className="btn text-white w-100"
                      style={{ background: "#004e73" }}
                      width="100%"
                      height="43px"
                      id="login-button"
                      onClick={formik.handleSubmit}
                      // disabled={buttonLoader}
                      type="button"
                    >
                      {loading ? <ButtonLoader /> : "Sign In"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className=""></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginResponse: state.login.storeLoginResponse,
  loginError: state.login.error,
  preLoader: state.login.loading,
});
const mapDispatchToProps = (dispatch) => ({
  LoginUser: (data) => dispatch(LoginUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
