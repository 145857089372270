/** ****************************** Import libs *********************************** */

const URL_CONSTANTS = {
  login: "login",
  logout: "auth/logout",
  refreshToken: "auth/refresh-tokens",
  contacts: "contacts",
  applicantDetails: "applicantDetails",
  jobposts: "jobposts",
  testimonials: "testimonials",
  blogs: "blogs",
  categories: "categories",
  users: "users",
  usersettings: "usersettings",
  organizationdetails: "organizationdetails",
  companystatistics: "companystatistics",
  socialmedialink: "socialmedialink",
  jobPosition: "jobPosition",
  blogCategories: "blogCategories",
  adCampaign: "adCampaign",
  employeeReview: "employeeReview",
  ourCustomers: "ourCustomers",
  blogSearch: "blogsSearch",
  ppcCampaign: "ppcCampaign",
  ppcSettings: "ppcSettings",
  campaignPdf: "campaignPdf",
  emailCampaign: "emailCampaign",
};

export { URL_CONSTANTS };
