const config = {
  production: {
    url: "https://www.admin.applogiq.org/",
    apiUrl: "https://y124ycg870.execute-api.ap-southeast-1.amazonaws.com/prod/",
    bucketName: "applogiq",
    AWS_REGION: "ap-south-1",
    ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
  developement: {
    url: "https://www.demo-admin.applogiq.org/",
    // portfolioUrl: "https://www.portfolio-share.applogiq.org/",
    portfolioUrl: "https://develop.d2p9ockfn9tcm8.amplifyapp.com/",
    apiUrl: "https://w0jw9t60c5.execute-api.ap-southeast-1.amazonaws.com/dev/",
    bucketName: "applogiq-dev-s3",
    AWS_REGION: "ap-southeast-1",
    ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
  local: {
    url: "http://localhost:5000/",
    portfolioUrl: "https://develop.d2p9ockfn9tcm8.amplifyapp.com/",
    apiUrl: "http://localhost:7001/dev/",
    bucketName: "applogiq-dev-s3",
    AWS_REGION: "ap-southeast-1",
    ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
};
export const environment = "developement";
const hostConfig = {
  WEB_URL: config[environment].url,
  PORTFOLIO_URL: config[environment].portfolioUrl,
  IMAGE_URL: `https://${config[environment].bucketName}.s3.ap-southeast-1.amazonaws.com`,
  // IMAGE_URL: `https://applogiq-dev-s3.s3.ap-southeast-1.amazonaws.com`,
  API_URL: config[environment].apiUrl,
  S3_BUCKET: `${config[environment].bucketName}`,
  ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  AWS_REGION: "ap-southeast-1",
};

const intervals = {
  reload: 1500,
};
export { hostConfig, intervals };
