/** **************************** Import Types ****************************** */

import {OURCUSTOMER_SUCCESS,OURCUSTOMER_FAILURE,OURCUSTOMER_REQUEST} from "../types/ourCustomerType";

const initialState = {
    loading:false,
    storeOurCustomerResponse:"",
    error:"",
}

const OurCustomerReducer=(state=initialState,action)=>{
    switch (action.type){
        case OURCUSTOMER_REQUEST:
            return{
                ...state,
                loading:true,
                storeOurCustomerResponse:"",
            };
        case OURCUSTOMER_SUCCESS:
            return{
                ...state,
                loading:false,
                storeOurCustomerResponse: action.payload.responseStatus,
                error:"",
            };
        case OURCUSTOMER_FAILURE:
            return{
                ...state,
                loading:false,
                storeOurCustomerResponse:"error",
                error:action.payload
            }
        default:
            return state;
    } 
}

export default  OurCustomerReducer;