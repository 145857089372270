/** **************************** Import Packages ****************************** */
import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import SpinnerImg from "./assets/images/suspense-loadder.gif";
/** **************************** Import Constants ****************************** */
import { DarkTheme } from "./constants/themes";

/** **************************** Import Pages ****************************** */
import Login from "./pages/Signin";

/** **************************** Import CSS ****************************** */
// import "./App.css";

const App = () => {
  const Layout = React.lazy(() => import("./container/Layout"));
  return (
    <ThemeProvider theme={DarkTheme}>
      <Router>
        <React.Suspense
          fallback={
            <div
              className="d-grid justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <img src={SpinnerImg} width="100%" alt="spinner" />
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path="/"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />

            <Route
              path="/home"
              name="Home"
              render={(props) =>
                localStorage.getItem("accessToken") ? (
                  <Layout {...props} />
                ) : (
                  (window.location.href = "/")
                )
              }
            />
            <Route path="*" exact={true} />
          </Switch>
        </React.Suspense>
      </Router>
      <ToastContainer
        limit={1}
        autoClose={3000}
        transition={Zoom}
        pauseOnFocusLoss={false}
        newestOnTop
      />
    </ThemeProvider>
  );
};

export default App;
