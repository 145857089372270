/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
  LOGIN_USERS_REQUEST,
  LOGIN_USERS_SUCCESS,
  LOGIN_USERS_FAILURE,
} from "../types/loginTypes";
import { postLoginRequestData } from "../../api/create";
import "react-toastify/dist/ReactToastify.css";

export const LoginUsersRequest = () => ({
  type: LOGIN_USERS_REQUEST,
});
export const LoginUsersSuccess = (users) => ({
  type: LOGIN_USERS_SUCCESS,
  payload: users,
});
export const LoginUsersFailure = (error) => ({
  type: LOGIN_USERS_FAILURE,
  payload: error,
});

export const LoginUser = (data) =>
  async function (dispatch) {
    dispatch(LoginUsersRequest());
    return await postLoginRequestData(data)
      .then((res) => {
        if (!res.error) {
          const loggedUser = res?.data?.results;
          const accessToken = res?.data?.accesstoken;
          // const refersToken = res?.data?.refersetoken;
          localStorage.setItem("accessToken", accessToken);
          // localStorage.setItem("refersToken", refersToken);
          localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
          dispatch(LoginUsersSuccess({ responseStatus: "success" }));
          toast.success("Logged in Successfully");
          setTimeout(() => {
            window.location.href = "/home/enquiry";
          }, 3000);
          return res;
        } else {
          dispatch(LoginUsersFailure(res.error));
          toast.error("Invalid Credentials");
        }
      })
      .catch((err) => console.log("Catch Error:", err));
  };
