/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
  USERSETTING_REQUEST,
  USERSETTING_SUCCESS,
  USERSETTING_FAILURE,

} from "../types/userSettingType";
import { postUserSettingData } from "../../api/create";
import { updateUserSetttingPostData } from "../../api/updates";
import { getUserSettingList, getUserSettingListById } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const UserSettingPostRequest = () => ({
  type: USERSETTING_REQUEST,
});
export const UserSettingPostSuccess = (users) => ({
  type: USERSETTING_SUCCESS,
  payload: users,
});
export const UserSettingPostFailure = (error) => ({
  type: USERSETTING_FAILURE,
  payload: error,
});


export const CreateUserSettingPost = (data) => async function (dispatch) {
  dispatch(UserSettingPostRequest());
  return postUserSettingData(data)
    .then((res) => {
      if (!res.code) {
        dispatch(UserSettingPostSuccess({ responseStatus: "success" }));
        toast.success("Job Post created successfully");
        return "success";
      }
      dispatch(UserSettingPostFailure(res.message));
      toast.error(res.message);
      return res.message;
    }).catch((err) => console.log("Catch Error:", err));
};

export const UpdateUserPost = (data, userId) => async function (dispatch) {
  dispatch(UserSettingPostRequest());
  return updateUserSetttingPostData(data, userId)
    .then((res) => {
      if (!res.code && !res.error) {
        dispatch(UserSettingPostSuccess({ responseStatus: "success" }));
        toast.success("User Updated Successfully");
        return "success";
      }
      dispatch(UserSettingPostFailure(res.error));
      toast.error(res.message || res.error);
      return res.message || res.error;
    }).catch((err) => console.log("Catch Error:", err));
};
export const GetUserSettingList = (params) => async function (dispatch) {
  dispatch(UserSettingPostRequest());
  return getUserSettingList({
    ...params,
  })
    .then((res) => {
      if (res.data.records) {
        dispatch(UserSettingPostSuccess({ responseStatus: res }));
        return res;
      }

      dispatch(UserSettingPostFailure(res.message));
      return res.message;
    })
    .catch((err) => console.log("Catch Error:", err));
};

export const GetUserPostById = (params) => async function (dispatch) {
  dispatch(UserSettingPostRequest());
  return getUserSettingListById(params)
    .then((res) => {
      if (res.data) {
        dispatch(UserSettingPostSuccess(res.data.records));
        return res;
      }

      dispatch(UserSettingPostFailure(res.message));
      return res.message;
    })
    .catch((err) => console.log("Catch Error:", err));
};


