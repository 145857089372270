/** **************************** Import Types ****************************** */
import {
  Add_PORTFOLIO_FAILURE,
  Add_PORTFOLIO_REQUEST,
  Add_PORTFOLIO_SUCCESS,
} from "../types/addPortfolioType";

const initialState = {
  loading: false,
  portfolioResponse: "",
  error: "",
};

const portfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case Add_PORTFOLIO_REQUEST:
      return {
        ...state,
        loading: true,
        portfolioResponse: "",
      };
    case Add_PORTFOLIO_SUCCESS:
      return {
        loading: false,
        portfolioResponse: action.payload.responseStatus,
        error: "",
      };
    case Add_PORTFOLIO_FAILURE:
      return {
        loading: false,
        portfolioResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default portfolioReducer;
