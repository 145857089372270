/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  OURCUSTOMER_FAILURE,
  OURCUSTOMER_REQUEST,
  OURCUSTOMER_SUCCESS,
} from "../types/ourCustomerType";

// ************************************ Import List ********************************
import { postOurCustomer } from "../../api/create";
import { getOurCustomerList } from "../../api/list";
import { updateOurCustomer } from "../../api/updates";

export const ourCustomerRequest = () => ({
  type: OURCUSTOMER_REQUEST,
});
export const ourCustomersuccess = (users) => ({
  type: OURCUSTOMER_SUCCESS,
  payload: users,
});
export const ourCustomerFailure = (error) => ({
  type: OURCUSTOMER_FAILURE,
  payload: error,
});

export const updateCustomer = (data, userId) =>
  async function (dispatch) {
    dispatch(ourCustomerRequest());
    return updateOurCustomer(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(ourCustomersuccess({ responseStatus: "success" }));
          toast.success("Customer Updated Successfully");
          return "success";
        }
        dispatch(ourCustomerFailure(res.error));
        toast.error(res.message || res.error);
        return res.message || res.error;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const CreateCustomer = (data) =>
  async function (dispatch) {
    dispatch(ourCustomerRequest());
    return postOurCustomer(data)
      .then((res) => {
        if (!res.code) {
          dispatch(ourCustomersuccess({ responseStatus: "success" }));
          toast.success("Customer created successfully");
          return "success";
        }
        dispatch(ourCustomerFailure(res.message));
        toast.error(res.message);
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const GetOurCustomerList = (params) =>
  async function (dispatch) {
    dispatch(ourCustomerRequest());
    return getOurCustomerList(params)
      .then((res) => {
        if (res.data.records) {
          dispatch(ourCustomersuccess({ responseStatus: res }));
          return res;
        }
        dispatch(ourCustomerFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
