/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
  SOCIALMEDIA_SUCCESS,
  SOCIALMEDIA_FAILURE,
  SOCIALMEDIA_REQUEST,
} from "../types/socialMediaType";
import { postSocialMedia } from "../../api/create";
import { updateSocialMediaData } from "../../api/updates";
import { getSocialMediaList, getSocialMediaById } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const SocialMediaRequest = () => ({
  type: SOCIALMEDIA_REQUEST,
});
export const SocialMediaSuccess = (users) => ({
  type: SOCIALMEDIA_SUCCESS,
  payload: users,
});
export const SocialMediaFailure = (error) => ({
  type: SOCIALMEDIA_FAILURE,
  payload: error,
});

export const CreateSocialMedia = (data) =>
  async function (dispatch) {
    dispatch(SocialMediaRequest());
    return postSocialMedia(data)
      .then((res) => {
        if (!res.code) {
          dispatch(SocialMediaSuccess({ responseStatus: "success" }));
          toast.success("Social Media created successfully");
          return "success";
        }
        dispatch(SocialMediaFailure(res.message));
        toast.error(res.message);
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const UpdateSocialMedia = (data, userId) =>
  async function (dispatch) {
    dispatch(SocialMediaRequest());
    return updateSocialMediaData(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(SocialMediaSuccess({ responseStatus: "success" }));
          toast.success("Social Media Updated Successfully");
          return "success";
        }
        dispatch(SocialMediaFailure(res.error));s
        toast.error(res.message || res.error);
        return res.message || res.error;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const GetSocialMediaList = (params) =>
  async function (dispatch) {
    dispatch(SocialMediaRequest());
    return getSocialMediaList({
      ...params,
    })
      .then((res) => {
        if (res.data.records) {
          dispatch(SocialMediaSuccess({ responseStatus: res }));
          return res;
        }
        dispatch(SocialMediaFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const GetSocialMediaById = (params) =>
  async function (dispatch) {
    dispatch(SocialMediaRequest());
    return getSocialMediaById(params)
      .then((res) => {
        if (res.results) {
          dispatch(SocialMediaSuccess(res.results));
          return res;
        }
        dispatch(SocialMediaFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
