/** **************************** Import Types ****************************** */
import {
 PPC_MESSAGE_FAILURE,
 PPC_MESSAGE_SUCCESS,
 PPC_MESSAGE_REQUEST
} from "../types/ppcType";
import { getPPCList } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const PpcContactRequest = () => ({
  type: PPC_MESSAGE_REQUEST,
});
export const PpcContactSuccess = (users) => ({
  type: PPC_MESSAGE_SUCCESS,
  payload: users,
});
export const PpcContactFailure = (error) => ({
  type: PPC_MESSAGE_FAILURE,
  payload: error,
});

export const GetPpcContactList = (params) => async function (dispatch) {
  dispatch(PpcContactRequest());
  return getPPCList({
    ...params,
  })
    .then((res) => {
      if (res?.data) {
        dispatch(PpcContactSuccess({ responseStatus: res }));
        return res;
      }

      dispatch(PpcContactFailure(res.message));
      return res.message;
    })
    .catch((err) => console.log("Catch Error:", err));
};
