/** **************************** Import Types ****************************** */
import {
PPC_TYPE_FAILURE,
PPC_TYPE_REQUEST,
PPC_TYPE_SUCCESS
} from "../types/ppcSettingTypes";

const initialState = {
  loading: false,
  storePpcTypeResponse: "",
  error: "",
};

const ppcCampaignTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case PPC_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        storePpcTypeResponse: "",
      };
    case PPC_TYPE_SUCCESS:
      return {
        loading: false,
        storePpcTypeResponse: action.payload.responseStatus,
        error: "",
      };
    case PPC_TYPE_FAILURE:
      return {
        loading: false,
        storePpcTypeResponse: "error",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ppcCampaignTypeReducer;
