/** ****************************** Import libs *********************************** */
import { postDataApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const postLoginRequestData = (params) =>
  postDataApi(URL_CONSTANTS.login, params);

export const postOurCustomer = (params)=>
postDataApi(URL_CONSTANTS.ourCustomers, params);

export const postOurEmployeeReview = (params)=>
postDataApi(URL_CONSTANTS.employeeReview, params);

export const postLogoutRequestData = (params) =>
  postDataApi(URL_CONSTANTS.logout, params);

export const postUserData = (params) =>
  postDataApi(URL_CONSTANTS.users, params);

export const postUserSettingData = (params) =>
  postDataApi(URL_CONSTANTS.usersettings, params);

export const postWorkDetail = (params) =>
  postDataApi(URL_CONSTANTS.jobposts, params);

export const postTestimonialDetail = (params) =>
  postDataApi(URL_CONSTANTS.testimonials, params);

export const postblog = (params) => postDataApi(URL_CONSTANTS.blogs, params);

export const postblogSetting = (params) =>
  postDataApi(URL_CONSTANTS.categories, params);
export const postUserSetting = (params) =>
  postDataApi(URL_CONSTANTS.usersettings, params);
export const postAddress = (params) =>
  postDataApi(URL_CONSTANTS.organizationdetails, params);
export const postCompanyStatistics = (params) =>
  postDataApi(URL_CONSTANTS.companystatistics, params);
export const postSocialMedia = (params) =>
  postDataApi(URL_CONSTANTS.socialmedialink, params);
export const postUserProfileImage = (params, id) =>
  postDataApi(URL_CONSTANTS.uploadProfileImage, params, id);

export const postblogCategory = (params) =>
  postDataApi(URL_CONSTANTS.blogCategories, params);
export const postJobPosition = (params) =>
  postDataApi(URL_CONSTANTS.jobPosition, params);

export const postPpcType = (params) =>
  postDataApi(URL_CONSTANTS.ppcSettings, params);

export const postPpcRegion = (params) =>
  postDataApi(URL_CONSTANTS.ppcSettings, params);

export const postPortfolio = (params) =>
  postDataApi(URL_CONSTANTS.campaignPdf, params);
