/** ****************************** Import libs *********************************** */
import { getListByApi, viewDataByApi, downloadApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const getPocList = (params) =>
  getListByApi(URL_CONSTANTS.adCampaign, params);

export const getOurCustomerList = (params) =>
  getListByApi(URL_CONSTANTS.ourCustomers, params);

export const getOurCustomerById = (dataId) =>
  getListByApi(URL_CONSTANTS.ourCustomers, dataId);

export const getEmployeeReviewList = (params) =>
  getListByApi(URL_CONSTANTS.employeeReview, params);

export const getUserList = (params) =>
  getListByApi(URL_CONSTANTS.users, params);

export const getUserById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.users, dataId);

export const getAllOrganizations = (params) =>
  getListByApi(URL_CONSTANTS.organizations, params);

export const getOrganizationById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.organizations, dataId);

export const getUserProfile = (params) =>
  getListByApi(URL_CONSTANTS.userProfile, params);

export const getUserProfileById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.userProfile, dataId);

export const getOfferLetter = (params) =>
  getListByApi(URL_CONSTANTS.viewOfferLetter, params);

export const downloadOfferLetter = (dataId) =>
  downloadApi(URL_CONSTANTS.viewOfferLetter, dataId);

export const getContactMessages = (params) =>
  getListByApi(URL_CONSTANTS.contacts, params);

export const getCarrierMessages = (params) =>
  getListByApi(URL_CONSTANTS.applicantDetails, params);

export const getJobPostList = (params) =>
  getListByApi(URL_CONSTANTS.jobposts, params);

export const getJobPostById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.jobposts, dataId);

export const getTestimonialList = (params) =>
  getListByApi(URL_CONSTANTS.testimonials, params);

export const getSocialMediaList = (params) =>
  getListByApi(URL_CONSTANTS.socialmedialink, params);

export const getSocialMediaById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.socialmedialink, dataId);

export const getCompanyStatisticsList = (params) =>
  getListByApi(URL_CONSTANTS.companystatistics, params);

export const getCompanyStatisticsById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.companystatistics, dataId);

export const getTestimonialById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.testimonials, dataId);

export const getblogcategoryList = (params) =>
  getListByApi(URL_CONSTANTS.blogCategories, params);

export const getblogCategoryListById = (dataId) =>
  getListByApi(URL_CONSTANTS.blogCategories, dataId);

export const getblogList = (params) =>
  getListByApi(URL_CONSTANTS.blogs, params);

export const getblogListById = (dataId) =>
  getListByApi(URL_CONSTANTS.blogs, dataId);

export const getblogSettingList = (params) =>
  getListByApi(URL_CONSTANTS.categories, params);

export const getUserSettingList = (params) =>
  getListByApi(URL_CONSTANTS.usersettings, params);

export const getUserSettingListById = (dataId) =>
  getListByApi(URL_CONSTANTS.usersettings, dataId);

export const getAddressList = (params) =>
  getListByApi(URL_CONSTANTS.organizationdetails, params);

export const getAddressById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.organizationdetails, dataId);


export const getjobPositionList = (params) =>
  getListByApi(URL_CONSTANTS.jobPosition, params);

export const getjobPositionById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.jobPosition, dataId);

export const getBlogSearch = (params) =>
  getListByApi(URL_CONSTANTS.blogSearch, params);

export const getPPCList = (params) =>
  getListByApi(URL_CONSTANTS.ppcCampaign, params);

export const getPPCTypeList = (params) =>
  getListByApi(URL_CONSTANTS.ppcSettings, params);

export const getPPCRegionList = (params) =>
  getListByApi(URL_CONSTANTS.ppcSettings, params);


export const getPpcTypeById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.ppcSettings, dataId);

export const getPpcRegionById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.ppcSettings, dataId);   


export const getPortfolioList = (params) =>
  getListByApi(URL_CONSTANTS.campaignPdf, params);

export const getPortfolioById = (dataId) =>
  viewDataByApi(URL_CONSTANTS.campaignPdf, dataId);

export const getPortfolioViewList = (params) =>
  getListByApi(URL_CONSTANTS.emailCampaign, params);
