/** **************************** Import Types ****************************** */
import {
  PPC_MESSAGE_FAILURE,PPC_MESSAGE_REQUEST,PPC_MESSAGE_SUCCESS
  } from "../types/ppcType";
  
  const initialState = {
    loading: false,
    storePpcResponse: "",
    error: "",
  };
  
  const PpcMessageReducer = (state = initialState, action) => {
    switch (action.type) {
      case PPC_MESSAGE_REQUEST:
        return {
          ...state,
          loading: true,
          storePpcResponse: "",
        };
      case PPC_MESSAGE_SUCCESS:
        return {
          loading: false,
          storePpcResponse: action.payload.responseStatus,
          error: "",
        };
      case PPC_MESSAGE_FAILURE:
        return {
          loading: false,
          storePpcResponse: "error",
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default PpcMessageReducer;
  