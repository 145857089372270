/** **************************** Import Types ****************************** */

import {
    EMPLOYEEREVIEW_REQUEST,
    EMPLOYEEREVIEW_SUCCESS,
    EMPLOYEEREVIEW_FAILURE,
} from "../types/employeeReviewType";

const initialState = {
    loading:false,
    storeEmployeeReviewResponse:"",
    error:"",
}

const EmployeeReviewReducer=(state=initialState,action)=>{
    switch (action.type){
        case EMPLOYEEREVIEW_REQUEST:
            return{
                ...state,
                loading:true,
                storeEmployeeReviewResponse:"",
            };
        case EMPLOYEEREVIEW_SUCCESS:
            return{
                ...state,
                loading:false,
                storeEmployeeReviewResponse: action.payload.responseStatus,
                error:"",
            };
        case EMPLOYEEREVIEW_FAILURE:
            return{
                ...state,
                loading:false,
                storeEmployeeReviewResponse:"error",
                error:action.payload,
            }
        default:
            return state;
    } 
}

export default  EmployeeReviewReducer;