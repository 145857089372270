/** **************************** Import Types ****************************** */
import {
    TESTIMONIAL_REQUEST,
    TESTIMONIAL_SUCCESS,
    TESTIMONIAL_FAILURE,

} from "../types/testimonialType";

const initialState = {
    loading: false,
    testimonialResponse: "",
    error: "",
};

const testimonialReducer = (state = initialState, action) => {

    switch (action.type) {
        case TESTIMONIAL_REQUEST:
            return {
                ...state,
                loading: true,
                testimonialResponse: "",
            };
        case TESTIMONIAL_SUCCESS:
            return {
                loading: false,
                testimonialResponse: action.payload.responseStatus,
                error: "",
            };
        case TESTIMONIAL_FAILURE:
            return {
                loading: false,
                testimonialResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default testimonialReducer;
