/** **************************** Import Types ****************************** */
import {
  JOBPOSITION_REQUEST,
  JOBPOSITION_SUCCESS,
  JOBPOSITION_FAILURE,
} from "../types/jobPositionType";

const initialState = {
  loading: false,
  storejobPositionResponse: "",
  error: "",
};

const jobPositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOBPOSITION_REQUEST:
      return {
        ...state,
        loading: true,
        storejobPositionResponse: "",
      };
    case JOBPOSITION_SUCCESS:
      return {
        loading: false,
        storejobPositionResponse: action.payload.responseStatus,
        error: "",
      };
    case JOBPOSITION_FAILURE:
      return {
        loading: false,
        storejobPositionResponse: "error",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default jobPositionReducer;
