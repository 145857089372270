/** **************************** Import Types ****************************** */
import {
    POC_MESSAGE_REQUEST,
    POC_MESSAGE_SUCCESS,
    POC_MESSAGE_FAILURE,
} from "../types/pocType";

const initialState = {
    loading: false,
    storePocMessageResponse: "",
    error: "",
};

const PocMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case POC_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true,
                storePocMessageResponse: "",
            };
        case POC_MESSAGE_SUCCESS:
            return {
                loading: false,
                storePocMessageResponse: action.payload.responseStatus,
                error: "",
            };
        case POC_MESSAGE_FAILURE:
            return {
                loading: false,
                storePocMessageResponse: "error",
                error: action.payload,
            };
        default:
            return state;
    }
};

export default PocMessageReducer;
