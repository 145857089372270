/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
  ADDERSS_SUCCESS,
  ADDERSS_FAILURE,
  ADDERSS_REQUEST,
} from "../types/addressType";
import { postAddress } from "../../api/create";
import { updateAddressData } from "../../api/updates";
import { getAddressList, getAddressById } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const AddressRequest = () => ({
  type: ADDERSS_REQUEST,
});
export const AddressSuccess = (users) => ({
  type: ADDERSS_SUCCESS,
  payload: users,
});
export const AddressFailure = (error) => ({
  type: ADDERSS_FAILURE,
  payload: error,
});


export const CreateAddress = (data) => async function (dispatch) {
  dispatch(AddressRequest());
  return postAddress(data)
    .then((res) => {
      if (!res.code) {
        dispatch(AddressSuccess({ responseStatus: "success" }));
        toast.success("Address created successfully");
        return "success";
      }
      dispatch(AddressFailure(res.message));
      toast.error(res.message);
      return res.message;
    }).catch((err) => console.log("Catch Error:", err));
};

export const UpdateAddress = (data, userId) =>
  async function (dispatch) {
    dispatch(AddressRequest());
    return updateAddressData(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(AddressSuccess({ responseStatus: "success" }));
          toast.success("Org Address Updated Successfully");
          return "success";
        }
        dispatch(AddressFailure(res.error));
        toast.error(res.message || res.error);
        return res.message || res.error;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const GetAddressList = (params) => async function (dispatch) {
  dispatch(AddressRequest());
  return getAddressList({
    ...params,
  })
    .then((res) => {
      if (res.data.records) {
        dispatch(AddressSuccess({ responseStatus: res }));
        return res;
      }

      dispatch(AddressFailure(res.message));
      return res.message;
    })
    .catch((err) => console.log("Catch Error:", err));
};
export const GetAddressById = (params) => async function (dispatch) {
  dispatch(AddressRequest());
  return getAddressById(params)
    .then((res) => {
      if (res.results) {
        dispatch(AddressSuccess(res.results));
        return res;
      }

      dispatch(AddressFailure(res.message));
      return res.message;
    })
    .catch((err) => console.log("Catch Error:", err));
};


