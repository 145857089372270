/** **************************** Import Types ****************************** */
import {
    BLOGCATEGORY_SUCCESS,
    BLOGCATEGORY_FAILURE,
    BLOGCATEGORY_REQUEST,
} from "../types/blogCategoryType";

const initialState = {
    loading: false,
    blogCategoryResponse: "",
    error: "",
};

const blogCategoryReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case BLOGCATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                blogCategoryResponse: "",
            };
        case BLOGCATEGORY_SUCCESS:
            return {
                loading: false,
                blogCategoryResponse: action.payload.responseStatus,
                error: "",
            };
        case BLOGCATEGORY_FAILURE:
            return {
                loading: false,
                blogCategoryResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default blogCategoryReducer;
