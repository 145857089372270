/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
  COMPANYSTATISTICS_SUCCESS,
  COMPANYSTATISTICS_FAILURE,
  COMPANYSTATISTICS_REQUEST,
} from "../types/companyStatisticsType";
import { postCompanyStatistics } from "../../api/create";
import { updateCompanyStatisticsData } from "../../api/updates";
import {
  getCompanyStatisticsList,
  getCompanyStatisticsById,
} from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const CompanyStatisticsRequest = () => ({
  type: COMPANYSTATISTICS_REQUEST,
});
export const CompanyStatisticsSuccess = (users) => ({
  type: COMPANYSTATISTICS_SUCCESS,
  payload: users,
});
export const CompanyStatisticsFailure = (error) => ({
  type: COMPANYSTATISTICS_FAILURE,
  payload: error,
});

export const CreateCompanyStatistics = (data) =>
  async function (dispatch) {
    dispatch(CompanyStatisticsRequest());
    return postCompanyStatistics(data)
      .then((res) => {
        if (!res.code) {
          dispatch(CompanyStatisticsSuccess({ responseStatus: "success" }));
          toast.success("Company Statistics created successfully");
          return "success";
        }
        dispatch(CompanyStatisticsFailure(res.message));
        toast.error(res.message);
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const UpdateCompanyStatistics = (data, userId) =>
  async function (dispatch) {
    dispatch(CompanyStatisticsRequest());
    return updateCompanyStatisticsData(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(CompanyStatisticsSuccess({ responseStatus: "success" }));
          toast.success("Company Statistics Updated Successfully");
          return "success";
        }
        dispatch(CompanyStatisticsFailure(res.error));
        toast.error(res.message || res.error);
        return res.message || res.error;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const GetCompanyStatisticsList = (params) =>
  async function (dispatch) {
    dispatch(CompanyStatisticsRequest());
    return getCompanyStatisticsList({
      ...params,
    })
      .then((res) => {
        if (res.data.records) {
          dispatch(CompanyStatisticsSuccess({ responseStatus: res }));
          return res;
        }
        dispatch(CompanyStatisticsFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const GetCompanyStatisticsById = (params) =>
  async function (dispatch) {
    dispatch(CompanyStatisticsRequest());
    return getCompanyStatisticsById(params)
      .then((res) => {
        if (res.results) {
          dispatch(CompanyStatisticsSuccess(res.results));
          return res;
        }
        dispatch(CompanyStatisticsFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
