/** **************************** Import Types ****************************** */
import {
    POC_MESSAGE_REQUEST,
    POC_MESSAGE_SUCCESS,
    POC_MESSAGE_FAILURE,
} from "../types/pocType";
import { getPocList } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const PocMessagesRequest = () => ({
    type: POC_MESSAGE_REQUEST,
});
export const PocMessagesSuccess = (users) => ({
    type: POC_MESSAGE_SUCCESS,
    payload: users,
});
export const PocMessagesFailure = (error) => ({
    type: POC_MESSAGE_FAILURE,
    payload: error,
});

export const GetPocMessageList = (params) => async function (dispatch) {
    dispatch(PocMessagesRequest());
    return getPocList({
        ...params,
    })
        .then((res) => {
            if (res.data) {
                dispatch(PocMessagesSuccess({ responseStatus: res }));
                return res;
            }

            dispatch(PocMessagesFailure(res.message));
            return res.message;
        })
        .catch((err) => console.log("Catch Error:", err));
};
