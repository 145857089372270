/** **************************** Import Types ****************************** */
import {
    USERSETTING_REQUEST,
    USERSETTING_SUCCESS,
    USERSETTING_FAILURE,

} from "../types/userSettingType";

const initialState = {
    loading: false,
    userSettingpostResponse: "",
    error: "",
};

const userSettingPostReducer = (state = initialState, action) => {

    switch (action.type) {
        case USERSETTING_REQUEST:
            return {
                ...state,
                loading: true,
                userSettingpostResponse: "",
            };
        case USERSETTING_SUCCESS:
            return {
                loading: false,
                userSettingpostResponse: action.payload.responseStatus,
                error: "",
            };
        case USERSETTING_FAILURE:
            return {
                loading: false,
                userSettingpostResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default userSettingPostReducer;
