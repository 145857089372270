/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
 Add_PORTFOLIO_FAILURE,Add_PORTFOLIO_REQUEST,Add_PORTFOLIO_SUCCESS
} from "../types/addPortfolioType";

import { postPortfolio } from "../../api/create";
import { updatePortfolioData } from "../../api/updates";
import { getPortfolioList, getPortfolioById } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const PortfolioRequest = () => ({
  type: Add_PORTFOLIO_REQUEST,
});
export const PortfolioSuccess = (users) => ({
  type: Add_PORTFOLIO_SUCCESS,
  payload: users,
});
export const PortfolioFailure = (error) => ({
  type: Add_PORTFOLIO_FAILURE,
  payload: error,
});

export const CreatePortfolio = (data) =>
  async function (dispatch) {
    dispatch(PortfolioRequest());
    return postPortfolio(data)
      .then((res) => {
        if (!res.data) {
          dispatch(PortfolioSuccess({ responseStatus: "success" }));
          toast.success(" Portfolio created successfully");
          return "success";
        }
        dispatch(PortfolioFailure(res));
        toast.error(res.data);
        return res;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const UpdatePortfolio = (data, userId) =>
  async function (dispatch) {
    dispatch(PortfolioRequest());
    return updatePortfolioData(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(PortfolioSuccess({ responseStatus: "success" }));
          toast.success("Portfolio Updated Successfully");
          return "success";
        }
        dispatch(PortfolioFailure(res.error));
        toast.error(res.message || res.error);
        return res.message || res.error;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const GetPortfolioList = (params) =>
  async function (dispatch) {
    dispatch(PortfolioRequest());
    return getPortfolioList({
      ...params,
    })
      .then((res) => {
        if (res?.data?.records) {
          dispatch(PortfolioSuccess({ responseStatus: res }));
          return res;
        }

        dispatch(PortfolioFailure(res?.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const GetPortfolioById = (params) =>
  async function (dispatch) {
    dispatch(PortfolioRequest());
    return getPortfolioById(params)
      .then((res) => {
        if (res.data) {
          dispatch(PortfolioSuccess(res.data.records));
          return res;
        }

        dispatch(postPortfolio(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
