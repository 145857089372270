/** **************************** Import Types ****************************** */
import {
  PORTFOLIO_VIEW_REQUEST,
  PORTFOLIO_VIEW_SUCCESS,
  PORTFOLIO_VIEW_FAILURE,
} from "../types/portfolioViewType";

import { getPortfolioViewList } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const PortfolioViewRequest = () => ({
  type: PORTFOLIO_VIEW_REQUEST,
});
export const PortfolioViewSuccess = (users) => ({
  type: PORTFOLIO_VIEW_SUCCESS,
  payload: users,
});
export const PortfolioViewFailure = (error) => ({
  type: PORTFOLIO_VIEW_FAILURE,
  payload: error,
});

export const GetPortfolioViewList = (params) => async function (dispatch) {
  dispatch(PortfolioViewRequest());
  return getPortfolioViewList({
    ...params,
  })
    .then((res) => {
      if (res?.data) {
        dispatch(PortfolioViewSuccess({ responseStatus: res }));
        return res;
      }

      dispatch(PortfolioViewFailure(res.message));
      return res.message;
    })
    .catch((err) => console.log("Catch Error:", err));
};
