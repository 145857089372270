/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EMPLOYEEREVIEW_FAILURE,
  EMPLOYEEREVIEW_REQUEST,
  EMPLOYEEREVIEW_SUCCESS,
} from "../types/employeeReviewType";

// ************************************ Import List ********************************
import { postOurEmployeeReview } from "../../api/create";
import { getEmployeeReviewList, getOurCustomerById } from "../../api/list";
import { updateEmployeeReview } from "../../api/updates";

export const employeeReviewRequest = () => ({
  type: EMPLOYEEREVIEW_REQUEST,
});
export const employeeReviewSuccess = (users) => ({
  type: EMPLOYEEREVIEW_SUCCESS,
  payload: users,
});
export const employeeReviewFailure = (error) => ({
  type: EMPLOYEEREVIEW_FAILURE,
  payload: error,
});

export const updateEmployee = (data, userId) =>
  async function (dispatch) {
    dispatch(employeeReviewRequest());
    return updateEmployeeReview(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(employeeReviewSuccess({ responseStatus: "success" }));
          toast.success("Employee Updated Successfully");
          return "success";
        }
        dispatch(employeeReviewFailure(res.error));
        toast.error(res.message || res.error);
        return res.message || res.error;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const CreateEmployee = (data) =>
  async function (dispatch) {
    dispatch(employeeReviewRequest());
    return postOurEmployeeReview(data)
      .then((res) => {
        if (!res.code) {
          dispatch(employeeReviewSuccess({ responseStatus: "success" }));
          toast.success("Employee created successfully");
          return "success";
        }
        dispatch(employeeReviewFailure(res.message));
        toast.error(res.message);
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const GetEmployeeReviewList = (params) =>
  async function (dispatch) {
    dispatch(employeeReviewRequest());
    return getEmployeeReviewList({
      ...params,
    })
      .then((res) => {
        if (res.data.records) {
          dispatch(employeeReviewSuccess({ responseStatus: res }));
          return res;
        }
        dispatch(employeeReviewFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const GetCustomerById = (params) =>
  async function (dispatch) {
    dispatch(employeeReviewRequest());
    return getOurCustomerById(params)
      .then((res) => {
        if (res.data) {
          dispatch(employeeReviewSuccess(res.data.records));
          return res;
        }

        dispatch(employeeReviewFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
