/** **************************** Import Types ****************************** */
import {
  PORTFOLIO_VIEW_REQUEST,
  PORTFOLIO_VIEW_SUCCESS,
  PORTFOLIO_VIEW_FAILURE,
} from "../types/portfolioViewType";
 

const initialState = {
  loading: false,
  storePortfolioViewResponse: "",
  error: "",
};

const PortfolioViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case PORTFOLIO_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
        storePortfolioViewResponse: "",
      };
    case PORTFOLIO_VIEW_SUCCESS:
      return {
        loading: false,
        storePortfolioViewResponse: action.payload.responseStatus,
        error: "",
      };
    case PORTFOLIO_VIEW_FAILURE:
      return {
        loading: false,
        storePortfolioViewResponse: "error",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default PortfolioViewReducer;
