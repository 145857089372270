/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
  JOBPOSITION_SUCCESS,
  JOBPOSITION_FAILURE,
  JOBPOSITION_REQUEST,
} from "../types/jobPositionType";
import { postJobPosition } from "../../api/create";
import { updateJobPositionData } from "../../api/updates";
import { getjobPositionList, getjobPositionById } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const JobPositionRequest = () => ({
  type: JOBPOSITION_REQUEST,
});
export const JobPositionSuccess = (users) => ({
  type: JOBPOSITION_SUCCESS,
  payload: users,
});
export const JobPositionFailure = (error) => ({
  type: JOBPOSITION_FAILURE,
  payload: error,
});

export const CreateJobPosition = (data) =>
  async function (dispatch) {
    dispatch(JobPositionRequest());
    return postJobPosition(data)
      .then((res) => {
        if (!res.code) {
          dispatch(JobPositionSuccess({ responseStatus: "success" }));
          toast.success("Job Position created successfully");
          return "success";
        }
        dispatch(JobPositionFailure(res.message));
        toast.error(res.message);
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const UpdateJobPosition = (data, userId) =>
  async function (dispatch) {
    dispatch(JobPositionRequest());
    return updateJobPositionData(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(JobPositionSuccess({ responseStatus: "success" }));
          toast.success("User Updated Successfully");
          return "success";
        }
        dispatch(JobPositionFailure(res.error));
        toast.error(res.message || res.error);
        return res.message || res.error;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const GetJobPositionList = (params) =>
  async function (dispatch) {
    dispatch(JobPositionRequest());
    return getjobPositionList({
      ...params,
    })
      .then((res) => {
        if (res.data.records) {
          dispatch(JobPositionSuccess({ responseStatus: res }));
          return res;
        }
        dispatch(JobPositionFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const GetJobPositionById = (params) =>
  async function (dispatch) {
    dispatch(JobPositionRequest());
    return getjobPositionById(params)
      .then((res) => {
        if (res.results) {
          dispatch(JobPositionSuccess(res.results));
          return res;
        }
        dispatch(JobPositionFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
