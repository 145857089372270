/** **************************** Import Types ****************************** */
import {
  SOCIALMEDIA_REQUEST,
  SOCIALMEDIA_SUCCESS,
  SOCIALMEDIA_FAILURE,
} from "../types/socialMediaType";

const initialState = {
  loading: false,
  storesocialMediaResponse: "",
  error: "",
};

const socialMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOCIALMEDIA_REQUEST:
      return {
        ...state,
        loading: true,
        storesocialMediaResponse: "",
      };
    case SOCIALMEDIA_SUCCESS:
      return {
        loading: false,
        storesocialMediaResponse: action.payload.responseStatus,
        error: "",
      };
    case SOCIALMEDIA_FAILURE:
      return {
        loading: false,
        storesocialMediaResponse: "error",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default socialMediaReducer;
